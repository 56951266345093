<template>
	<div class="details">
		<div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="../../assets/images/ie/EDI-800.jpg">
                </div>
				<div class="con_top_right">
					<h2>EDI许可证申办咨询服务</h2>
					<p class="new_tit">EDI许可证为增值电信业务经营许可证-在线数据处理与交易处理业务，属于第B21类增值电信业务。在线 数据处理与交易处理业务包括交易处理业务、电子数据交换业务和网络/电子设备数据处理业务。</p>
                    <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">在线咨询</span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                           <ul>
                               <li>业务范围：利用各种与通信网络相连的数据与交易、事务处理应用平台，通过通信网络为用户提供在线数据处理和交易、事务处理的业务牌照。</li>
                               <li>审批机构：跨地区业务在工信部办理，省内业务在各省通信管理局办理。</li>
                               <li>有效期限：5年有效期，5年后需要办理续期，获证后每年需要依规开展定级备案工作。</li>
                           </ul>
                        </div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>
		<div class="conten_body">  
			<div class="conten_left">
				
				
            
        <!-- 为什么选择万方？万方有什么优势？ -->
            <div class="service">
                <div class="title">
                    <h2>ICP许可证申办咨询服务</h2>
                </div>
                <div class="service_img">
                    <img src="@/assets/images/ie/00.jpg" alt="">
                </div>
            </div>


            <div class="scene">
                <div class="title">
                    <h3>业务场景</h3> 
                </div>

                <div class="scene_content">
                    <ul>哪些业务需要办EDI许可证
                      <li>①　企业基于互联网的增值模式涉及在线交易和数据处理平台业务。</li>
                      <li>②　交易处理业务，例如淘宝、京东、天猫等电商类平台。</li>
                      <li>③　电子数据交换业务，例如贸易、行政事务数据结构化、自动处理和交换服务等。</li>
                    </ul>
                    <p>总之，只要是依托互联网为第三方提供线上购物、物联网交易、平台交易、P2P交易等的系统均需办理EDI许可证。</p>
                </div>
            </div>


            <div class="develop">
                  <div class="title">
                    <h3>业务开展的必要性</h3> 
                </div>

                <div class="develop_content">
                    <ul>一、行政法规要求
                        <li>
                            <p>①　《电信条例》第七条，国家对电信业务经营采取实行许可制度；取得许可证才可经营电信业务。未取得电信业务经营许可证，任何组织或者个人不得从事电信业务经营活动。</p>
                            <div class="lis_img">
                                <img src="../../assets/images/ie/01.jpg" alt="">
                            </div>
                        </li>
                        <li>
                            <p>②　《电信条例》第九条指出，经营增值电信业务，须经过审查批准方可取得《增值电信业务经营许可证》。</p>
                            <div class="lis_img">
                                <img src="../../assets/images/ie/02.jpg" alt="">
                            </div>
                        </li>
                        <li>
                              <p>③　《电信条例》第七十条指出，无证经营电信业务将没收违法所得并处违法所得3倍以上5倍以下罚款。</p>
                            <div class="lis_img">
                                <img src="../../assets/images/ie/03.jpg" alt="">
                            </div>
                        </li>
                    </ul>
                    <ul>
                        二、违规事件
                        <li>
                             <p>①　2019年，上海某公司因无证经营电信业务，违规开展进行业务经营活动，没收违法所得人民币一个多亿、处以违法所得三倍罚款人民币三个多亿，共被处罚四个多亿。</p>
                            <div class="lis_img">
                                <img src="../../assets/images/ie/04.jpg" alt="">
                            </div>
                        </li>
                        <li>
                             <p>②　2022年，广东省相关部门通报处罚多家无证经营电信业务公司。处罚决定责令限期改正，没收违法所得上百万，并处违法所得3倍罚款数百万。</p>
                            <div class="lis_img">
                                <img src="../../assets/images/ie/05.jpg" alt="">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="profit">
                 <div class="title">
                    <h3>客户收益</h3> 
                </div>
                <div class="profit_content">
                    <ul>
                        <li>
                            <div class="pro_img">
                                <img src="../../assets/images/ie/1.png" alt="">
                            </div>
                            <p>合法合规重要凭证</p>
                        </li>
                        <li>
                            <div class="pro_img">
                                <img src="../../assets/images/ie/2.png" alt="">
                            </div>
                            <p>商业合作敲门砖</p>
                        </li>
                        <li>
                            <div class="pro_img">
                                <img src="../../assets/images/ie/3.png" alt="">
                            </div>
                            <p>持续经营基本保障</p>
                        </li>
                        <li style="width:50%">
                            <div class="pro_img">
                                <img src="../../assets/images/ie/4.png" alt="">
                            </div>
                            <p>互联网经营必备资质</p>
                        </li>
                        <li style="width:50%">
                            <div class="pro_img">
                                <img src="../../assets/images/ie/5.png" alt="">
                            </div>
                            <p>享有政府红利的有利依据</p>
                        </li>
                        <li style="width:50%">
                            <div class="pro_img">
                                <img src="../../assets/images/ie/6.png" alt="">
                            </div>
                            <p>行业准入必备前置</p>
                        </li>
                        <li style="width:50%">
                            <div class="pro_img">
                                <img src="../../assets/images/ie/7.png" alt="">
                            </div>
                            <p>入驻平台基础凭证</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="time">
                 <div class="title">
                    <h3>业务办理时间</h3> 
                </div>

                <div class="time_content">
                    <div class="time_img">
                        <img src="../../assets/images/ie/8.png" alt="">
                    </div>
                    <p>业务申办时间通常为30-60天</p>
                </div>

            </div>

            <div class="advantage">
                  <div class="title">
                    <h3>我们的优势</h3> 
                </div>
                <div class="advantage_content">
                    <ul>
                        <li>1.一站式服务，专业团队提供一站式服务。</li>
                        <li>2.服务保障，完善的服务体系，提供优质服务。</li>
                        <li>3.申请周期短，规范申请材料，缩短客户申请周期。</li>
                        <li>4.终身免费咨询，一次合作，终身提供免费咨询服务。</li>
                        <li>5.网络安全保障，免费提供网络安全保障咨询服务。</li>
                    </ul>
                </div>
            </div>


            <div class="case">
                <div class="title">
                    <h3>典型案例</h3> 
                </div>
                <div class="case_content">
                    <ul>
                        <li>1.广州某某集团电子商务有限公司-《增值电信业务经营许可证》申请咨询服务</li>
                        <li>2.南京某某科技有限公司-《增值电信业务经营许可证》申请咨询服务</li>
                        <li>3.广州某某网络科技有限公司-《增值电信业务经营许可证》申请咨询服</li>
                    </ul>
                </div>
            </div>


			


				
 
			</div>
			<!-- right -->
			<div class="conten_right">
                <div class="phone">
                    <div class="phone_left">
                        <p>服务热线(微信同号)：</p>
                        <p>188 188 60088</p> 
                        <p>188 188 63388</p>
                    </div>
                    <div class="phone_right">
                        <img src="@/assets/images/app/code.png" alt="">
                    </div>
                </div>
                <div class="hot">
                    <div class="hot_title">
                        <h4>热门产品</h4>
                    </div>
                        <div class="hot_lists">
                            <ul>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/guangdong">
                                            <img src="@/assets/images/ie/dbcping_358.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>广东地区等保测评...</p>
                                        <p>协助客户按照有关...</p>
                                        <p>在线咨询</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/assessment">
                                            <img src="@/assets/images/app/008.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>安全评估报告（全国互联网安全管理服务平台要求提交的报告）</p>
                                        <p>App、安全评估报告...</p>
                                        <p>￥9800</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
			
		</div>
	</div>
</template>

<script type="text/javascript">
export default{

data(){
	return{
		transmitData:{
			title:"云上业务安全无忧保障计划初级套餐",
			content:"",
			price:'0',
			url:require('@/assets/images/yunsafe/222.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}

.service{
    margin-top: 100px;
    .title{
        text-align: center;
        h2{
            font-size: 30px;
            color: #0067AC;
            font-weight: bold;
        }
    }
    .service_img{
        width: 860px;
        height: 300px;
            margin: 30px auto;
        img{
            width: 100%;
            height: 100%;
        }
    }
}

.scene{
    margin-top: 10px;
    .title{
        background-color: #0067AC;
            h3{
                padding: 0px 50px;
                font-size: 28px;
                line-height: 52px;
                color: #0067AC;
                margin: 0px 650px 0px 0px;
                background-color: white;
                border-top: 2px solid #0067AC;
                border-bottom: 2px solid #0067AC;
                font-weight: bold;
            }
    }
    .scene_content{
        margin: 20px 0px 20px 40px;
        ul{
            margin-top: 40px;
            font-size: 22px;
            li{
                font-size: 20px;
                margin-top: 10px;
            }
            :nth-child(1){
                margin-top: 30px;
            }
        }
        p{
            font-size: 20px;
            margin-top: 26px;
            color: #0067AC;
            font-weight: bold;
        }
    }
}

.careful{
      margin-top: 10px;
    .title{
        background-color: #0067AC;
            h3{
                padding: 0px 50px;
                font-size: 28px;
                line-height: 52px;
                color: #0067AC;
                margin: 0px 420px 0px 0px;
                background-color: white;
                border-top: 2px solid #0067AC;
                border-bottom: 2px solid #0067AC;
                font-weight: bold;
            }
    }
    .careful_content{
        ul{
            li{
                p{
                    font-size: 22px;
                    margin-top: 30px;
                    line-height: 26px;
                  
                    margin-left: 30px;
                    margin-right: 45px;
                }
                .careful_img{
                    width: 860px;
                    height: 350px;
                    margin: 0 auto;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
                :nth-child(2){
                     .careful_img{
                         height: 200px;
                         margin-top: 20px;
                     }
                }
        }
    }
}

.develop{
          margin-top: 30px;
    .title{
        background-color: #0067AC;
            h3{
                padding: 0px 50px;
                font-size: 28px;
                line-height: 52px;
                color: #0067AC;
                margin: 0px 500px 0px 0px;
                background-color: white;
                border-top: 2px solid #0067AC;
                border-bottom: 2px solid #0067AC;
                font-weight: bold;
            }
    }

    .develop_content{
        ul{
            margin-top: 30px;
            font-size: 22px;
            margin-left: 30px;
            li{
                margin-top: 30px;

                p{
                    font-size: 20px;
                    line-height: 24px;
                    color: #0067AC;
                    

                }
                .lis_img{
                    width: 98%;
                    margin: 10px auto;
                    border: 3px solid #0067AC;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.profit{
     margin-top: 30px;
    .title{
        background-color: #0067AC;
            h3{
                padding: 0px 50px;
                font-size: 28px;
                line-height: 52px;
                color: #0067AC;
                margin: 0px 600px 0px 0px;
                background-color: white;
                border-top: 2px solid #0067AC;
                border-bottom: 2px solid #0067AC;
                font-weight: bold;
            }
    }
    .profit_content{
        margin-top: 50px;
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            li{
                width: 33.3%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 100px;
                .pro_img{
                    width: 120px;
                    height: 120px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                p{
                    font-size: 22px;
                    margin-top: 36px;
                    color: #0067AC;
                    font-weight: bold;
                }
            }
        }
    }
}

.time{
      margin-top: 30px;
    .title{
        background-color: #0067AC;
            h3{
                padding: 0px 50px;
                font-size: 28px;
                line-height: 52px;
                color: #0067AC;
                margin: 0px 600px 0px 0px;
                background-color: white;
                border-top: 2px solid #0067AC;
                border-bottom: 2px solid #0067AC;
                font-weight: bold;
            }
    }
    .time_content{
        margin-top:30px;
        .time_img{
            margin: 0 auto;
            width: 112px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        p{
            text-align: center;
            margin-top: 20px;
            font-size: 22px;
        }
    }
}

.advantage{
     margin-top: 50px;
    .title{
        background-color: #0067AC;
            h3{
                padding: 0px 50px;
                font-size: 28px;
                line-height: 52px;
                color: #0067AC;
                margin: 0px 600px 0px 0px;
                background-color: white;
                border-top: 2px solid #0067AC;
                border-bottom: 2px solid #0067AC;
                font-weight: bold;
            }
    }
    .advantage_content{
        margin-top:30px;
        ul{
            margin-left: 30px;
            li{
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
}

.case{
     margin-top: 50px;
     padding-bottom: 50px;
    .title{
        background-color: #0067AC;
            h3{
                padding: 0px 50px;
                font-size: 28px;
                line-height: 52px;
                color: #0067AC;
                margin: 0px 600px 0px 0px;
                background-color: white;
                border-top: 2px solid #0067AC;
                border-bottom: 2px solid #0067AC;
                font-weight: bold;
            }
    }
    .case_content{
        margin-top:50px;
        ul{
            margin-left: 30px;
            li{
                font-size: 20px;
                line-height: 30px;
                font-weight: bold;
            }
        }
    }
}



.conten_right{
	width:300px;
	background:#f4f4f4;
	float:right;
  display: block;
  min-height: 100%;
	margin-left:8px;
    .phone{
        width: 100%;
        height: 100px;
        background-color: #0067AC;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .phone_left{
            p{
                font-size: 16px;
                line-height: 18px;
                color: white;
            }
        }
        .phone_right{
            width: 75px;
            height: 75px;
            img{
                width:100%;
                height: 100%;
                }
        }
    }
    .hot{
        margin-top: 30px;
        background-color: #F2F2F2;
        height: 380px;
        .hot_title{
            width: 300px;
            height: 35px;
            background: #0067AC;
            h4{
                margin-left: 20px;
                font-size: 18px;
                line-height: 35px;
                color: white;
            }
        }
        .hot_lists{
            ul{
                li{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    .hot_left{
                        width: 147px;
                        height: 116px;
                        img{
                            width:147px;
                            height:116px;
                        }
                    }
                    .hot_right{
                        width: 140px;
                        margin-left: 20px;
                        p{
                            font-size: 14px;
                            line-height: 25px;
                             white-space: nowrap;    
                            overflow: hidden;         
                            text-overflow: ellipsis;
                        }
                        :nth-child(1){
                            padding-right: 25px;
                            font-weight: bold;
                           
                        }
                        :nth-child(2){
                             padding-right: 25px;
                            font-size: 13px;
                             
                        }
                         :nth-child(3){
                            font-size: 16px;
                            color: #B20909;
                           
                        }
                    }
                }
            }
        }
    }

}

.contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background-color:#0067AC;

	line-height: 24px;
	font-size:16px;
    padding:10px 0;
	color:#fff;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:10px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #FF0000;
	line-height: 47px;
    padding-left: 35px;
    padding-right: 2px;
    font-weight: bold;
}
.pri_box  .price{
	font-size: 26px;
	font-weight: bold;
	color: #FF0000;
	position: relative;
}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 4px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
        ul{
            li{
              
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}
.box_ti{
   margin-top:30px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:10px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}
.details{
	width:1200px;
	margin:0 auto;

//**/





}


</style>







